.theme-toggle {
  position: relative;
  width: 2rem;
  height: 1rem;
  border-radius: 1rem;
  margin-right: map-get($spacers, 2);
  margin-bottom: 0;

  &-input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  &-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:  var(--#{$variable-prefix}300);
    transition: 0.4s;
  }
  &-slider:before {
    position: absolute;
    content: "☀️";
    // content: "🌓";
    font-size: 0.6rem;
    height: 1.2rem;
    width: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--#{$variable-prefix}warning);
    left: 0;
    top: 50%;
    transform: translate3d(-10%, -50%, 0);
    background: var(--#{$variable-prefix}light);
    transition: all 0.4s ease-in-out;
    z-index: 2;
    box-shadow: var(--#{$variable-prefix}box-shadow);
  }
//   &-input:checked + &-slider {
//     background-color: var(--#{$variable-prefix}300);
//   }
  &-input:checked + &-slider:before {
    transform: translate3d(1.2rem, -50%, 0);
    background-color: var(--#{$variable-prefix}warning);
    content: "🌙";
  }
  &-slider.round {
    border-radius: 1.5rem;
  }
  &-slider.round:before {
    border-radius: 50%;
  }
}